<template>
    <div class="row" v-if="isValidView">
        <div class="col-12">
            <KTCard ref="preview" v-bind:example="true">
                <template v-slot:body>
                    <div>
                        <table id="my-table" aria-busy="false" aria-colcount="13"
                            class="table b-table table-borderless b-table-no-border-collapse table-''" role="table">
                            <thead class="thead-none" role="rowgroup">
                                <tr></tr>
                                <tr class="" role="row">
                                    <th class="text-center border border-top-0 border-right-0  font-size-lg ">
                                        <div><span>{{ $t('packing_list.po_number') }} <i
                                                    class="text-danger">*</i></span></div>
                                    </th>

                                    <th class="text-center border border-top-0 border-right-0  font-size-lg ">
                                        <div><span>{{ $t('packing_list.color_name') }} <i
                                                    class="text-danger">*</i></span></div>
                                    </th>

                                    <th class="text-center border border-top-0 border-right-0  font-size-lg ">
                                        <div><span>{{ $t('packing_list.color_number') }} <i
                                                    class="text-danger">*</i></span></div>
                                    </th>

                                    <th class="text-center border border-top-0 border-right-0  font-size-lg ">
                                        <div><span>{{ $t('packing_list.body_size') }} <i
                                                    class="text-danger">*</i></span></div>
                                    </th>

                                    <th class="text-center border border-top-0 border-right-0  font-size-lg "
                                        style="width: 95px;">
                                        <div><span>{{ $t('packing_list.request_quantity') }} <i
                                                    class="text-danger">*</i></span></div>
                                    </th>

                                    <th class="text-center border border-top-0 border-right-0  font-size-lg ">
                                        <div><span>{{ $t('packing_list.d_any') }} <i class="text-danger">*</i></span>
                                        </div>
                                    </th>

                                    <th class="text-center border border-top-0 border-right-0  font-size-lg ">
                                        <div><span>{{ $t('packing_list.box_quantity') }} <i
                                                    class="text-danger">*</i></span></div>
                                    </th>

                                    <th class="text-center border border-top-0 border-right-0  font-size-lg ">
                                        <div><span>{{ $t('packing_list.in_package') }} <i
                                                    class="text-danger">*</i></span></div>
                                    </th>

                                    <th class="text-center border border-top-0 border-right-0  font-size-lg ">
                                        <div><span>{{ $t('packing_list.broken_parcel') }}</span></div>
                                    </th>

                                    <th class="text-center border border-top-0 border-right-0  font-size-lg ">
                                        <div><span>{{ $t('packing_list.box_weight') }} <i
                                                    class="text-danger">*</i></span></div>
                                    </th>

                                    <th class="text-center border border-top-0 border-right-0  font-size-lg ">
                                        <div><span>{{ $t('packing_list.total') }} <i class="text-danger">*</i></span>
                                        </div>
                                    </th>
                                    <th class="text-center border border-top-0 border-right-0  font-size-lg ">
                                        <div><span>{{ $t('packing_list.total') }} + {{ $t('packing_list.broken_parcel') }}<i class="text-danger"></i></span></div>
                                    </th>
                                    <th class="text-center border border-top-0 border-right-0  font-size-lg ">
                                        <div><span>{{ $t('packing_list.date') }} <i class="text-danger">*</i></span>
                                        </div>
                                    </th>
                                    <th class="text-center border border-top-0 border-right-0  font-size-lg ">
                                        <div><span>{{ $t('packing_list.document_or_image_upload') }} <i
                                                    class="text-danger">*</i></span></div>
                                    </th>
                                </tr>
                            </thead>
                            <tbody role="rowgroup">
                                <!---->
                                <template v-for="(item, index) in orderInformations">
                                    <tr class="b-table-empty-row" role="row">
                                        <td class="" role="cell">
                                            <span class="flex-grow-1 text-sm py-1 px-1 text-center w-100" type="text">
                                                {{
                                                        item.po_number
                                                }}</span>
                                        </td>
                                        <td class="" role="cell">
                                            <span class="flex-grow-1 text-sm py-1 px-1 text-center w-100" type="text">
                                                {{
                                                        item.color_name
                                                }}</span>
                                        </td>
                                        <td class="" role="cell">
                                            <span class="flex-grow-1 text-sm py-1 px-1 text-center w-100" type="text">
                                                {{
                                                        item.color_number
                                                }}</span>
                                        </td>
                                        <td class="" role="cell">
                                            <span class="flex-grow-1 text-sm py-1 px-1 text-center w-100" type="text">
                                                {{
                                                        item.body_size
                                                }}</span>
                                        </td>
                                        <td class="" role="cell">
                                            <span class="flex-grow-1 text-sm py-1 px-1 text-center w-100" type="text">
                                                {{ item.amount }}</span>
                                        </td>
                                        <td class="" role="cell">
                                            <input v-model.sync="item.d_any" :placeholder="$t('esc.new_hand_enter')"
                                                class="flex-grow-1 text-sm form-control form-control-sm py-1 px-1 text-center w-100"
                                                type="text">
                                        </td>
                                        <td class="" role="cell">
                                            <input v-model="item.box_quantity" :placeholder="$t('esc.new_hand_enter')"
                                                class="flex-grow-1 text-sm form-control form-control-sm py-1 px-1 text-center w-100"
                                                type="text">
                                        </td>
                                        <td class="" role="cell">
                                            <input v-model="item.in_package" :placeholder="$t('esc.new_hand_enter')"
                                                class="flex-grow-1 text-sm form-control form-control-sm py-1 px-1 text-center w-100"
                                                type="text">
                                        </td>
                                        <td class="" role="cell">
                                            <input v-model="item.broken_parcel" :placeholder="$t('esc.new_hand_enter')"
                                                class="flex-grow-1 text-sm form-control form-control-sm py-1 px-1 text-center w-100"
                                                type="text">
                                        </td>
                                        <td class="" role="cell">
                                            <input v-model="item.box_weight" :placeholder="$t('esc.new_hand_enter')"
                                                class="flex-grow-1 text-sm form-control form-control-sm py-1 px-1 text-center w-100"
                                                type="text">
                                        </td>
                                        <td class="" role="cell">
                                            <input
                                                :v-model="item.broken_total_parcel"
                                                :disabled="true"
                                                :placeholder="$t('esc.new_hand_enter')"
                                                :value="item.broken_parcel>0?Number(item.box_quantity)+1:item.box_quantity"
                                                class="flex-grow-1 text-sm form-control form-control-sm py-1 px-1 text-center w-100"
                                                type="text">
                                        </td>
                                        <td class="" role="cell">
                                            <input :disabled="true" :placeholder="$t('esc.new_hand_enter')"
                                                :value="+item.box_quantity * +item.in_package + +item.broken_parcel"
                                                class="flex-grow-1 text-sm form-control form-control-sm py-1 px-1 text-center w-100"
                                                type="text">
                                        </td>
                                        <td class="" role="cell">
                                            <date-picker-input :is-valid="item.upload_date"
                                                :model.sync="item.upload_date" name="upload_date"></date-picker-input>
                                        </td>
                                        <td class="text-center" role="cell">
                                            <img v-show="item.image" :id="`preview-content-image-${index}`" alt=""
                                                class="w-50px h-50px object-cover border-15px navy-blue-border-1px cursor-pointer"
                                                @click="openUploadImage(`imageFileInput${index}`)">
                                            <file-input :ref="`imageFileInput${index}`" :model.sync="item.image"
                                                :name="`content-image-${index}`" :title="$t('editSample.image')"
                                                accept="image/*" @fileChange="onDocumentChange">
                                                <template v-if="!item.image" v-slot:inputDesign>
                                                    <div class="w-100 d-flex justify-content-center"
                                                        @click="openUploadImage(`imageFileInput${index}`)">
                                                        <span
                                                            v-html="getIconByKey('icons.waybill.waybill_entry', { class: 'w-30px h-30px d-inline-block object-fill cursor-pointer' })"></span>
                                                    </div>
                                                </template>
                                            </file-input>
                                        </td>
                                    </tr>
                                </template>
                            </tbody>
                        </table>
                    </div>

                    <div class="d-flex justify-content-center mt-5">
                        <button-with-icon :icon-name="null" :text="$t('general.delete')" class="mr-3 danger-border-1px"
                            size="lg" @onClick="deleteClick" v-if="isValidDelete"></button-with-icon>

                        <button-with-icon :icon-name="null" :text="$t('general.cancel')" class="mr-3 danger-border-1px"
                            size="lg" @onClick="cancelClick" v-if="isValidCancel"></button-with-icon>
                        <button-with-icon
                            :icon-name="null"
                            :text="$t('order.download_as_excel').toUpperCase()"
                            class="mx-2"
                            size="lg"
                            @onClick="openInNewTab(excelLink)"
                        ></button-with-icon>

                        <button-with-icon :icon-name="null" :text="$t('general.edit')" size="lg" v-if="isValidUpdate"
                            :disabled="!isValidToCreate"
                            @onClick="storeData()">
                        </button-with-icon>
                    </div>
                </template>
            </KTCard>
        </div>
    </div>

</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { mapGetters, mapMutations } from 'vuex'
import moduleUser, { GENERAL_SETTING, MODULE_NAME as MODULE_USER } from "@/core/services/store/user.module";
import store from "@/core/services/store";

import moment from "moment";

// COMPONENTS
import DashboardBox from "@/assets/components/DashboardBox";
import DataTable from "@/assets/components/dataTable/DataTable";
import InputFilter from "@/assets/components/filters/InputFilter";
import SelectFilter from "@/assets/components/filters/SelectFilter";
import DateFilter from "@/assets/components/filters/DateFilter";
import ButtonWithIcon from "@/assets/components/widget/ButtonWithIcon";
import FileInput from "@/assets/components/inputs/FileInput";
import DatePickerInput from "@/assets/components/inputs/DatePickerInput";
import {
    CREATE_ITEM,
    CURRENT_ITEM,
    DELETE_ITEM_BY_ID,
    ERROR,
    GET_ITEM_DETAIL_BY_ID,
    GET_ITEMS,
    LOADING,
    SET_ERROR,
    SET_ITEMS,
    SET_LOADING,
    SUCCESS,
    FILTER,
    SET_FILTER
} from "@/core/services/store/packing-list/packing_list_production.module"

import { LARAVEL_DATE_FORMAT, MOMENT_SYSTEM_DATE_FORMAT } from "@/core/config/constant";
import CustomMultiSelect from "@/assets/components/inputs/CustomMultiSelect";
import KTCard from "@/view/content/Card.vue";
import $ from "jquery";
import * as _ from "lodash";
import draggable from 'vuedraggable'
const _MODULE_USER = MODULE_USER;

export default {
    name: "PackingListIndex",
    beforeCreate() {
        function registerStoreModule(moduleName, storeModule) {
            if (!(store && store.state && store.state[moduleName])) {
                store.registerModule(moduleName, storeModule)
            }
        }

        registerStoreModule(_MODULE_USER, moduleUser);
    },
    components: {
        CustomMultiSelect,
        SelectFilter,
        InputFilter,
        DataTable,
        DashboardBox,
        ButtonWithIcon,
        DateFilter,
        KTCard,
        FileInput,
        draggable,
        DatePickerInput
    },
    computed: {
        ...mapGetters({
            loading: LOADING,
            error: ERROR,
            success: SUCCESS,
            packing: CURRENT_ITEM,
            filter: FILTER
        }),
        expenseCoefficient() {
            let expenseCoefficient = 10;

            if (this.settings) {
                let settings = _.first(this.settings);
                if (settings) {
                    expenseCoefficient = settings.fabric_request.expense_coefficient;
                }
            }
            return expenseCoefficient;
        },
        settings() {
            return this.$store.getters[_MODULE_USER + '/' + GENERAL_SETTING]
        },
        filters: {
            get: function () {
                return this.filter;
            },
            set: function (value) {
                this.setFilter(value);
            }
        },
        isValidToCreate() {
            let returnResult = true;

            this.orderInformations.forEach(item => {
                if (item.upload_date=='Invalid date') {
                    returnResult = false;
                    return;
                }
                if (item.box_quantity && (!item.d_any || !item.in_package || !item.box_weight)) {
                  returnResult = false;
                  return;
                }

                if (item.in_package && (!item.box_quantity || !item.d_any || !item.box_weight)) {
                  returnResult = false;
                  return;
                }

                if (item.box_weight && (!item.box_quantity || !item.in_package || !item.d_any)) {
                  returnResult = false;
                  return;
                }

                if (item.d_any && (!item.box_quantity || !item.in_package || !item.box_weight)) {
                  returnResult = false;
                  return;
                }
            })
            return returnResult;
        },
        isValidView() {
            if (this.status_id === 27 || this.status_id >= 27) {
                if (!this.isUserGranted('Staff', ['followingExport', 'superAdmin'], false)) {
                    return false;
                }
            }
            return true;
        },
        isValidDelete() {
            if (!this.isUserGranted('Staff', ['followingExport', 'superAdmin'], false)) {
                return false;
            }
            return true;
        },
        isValidCancel() {
            if (!this.isUserGranted('Staff', ['followingExport', 'superAdmin'], false)) {
                return false;
            }
            return true;
        },
        isValidUpdate() {
            if (this.status_id === 27 || this.status_id >= 27) {
                if (!this.isUserGranted('Staff', ['followingExport', 'superAdmin'], false)) {
                    return false;
                }
            }
            if (!this.isUserGranted('PackingList', ['update'], false)) {
                return false;
            }
            let orderInformations = this.orderInformations;
            orderInformations.forEach(item => {
                   if(typeof item.upload_date == 'string')return false;
                });
            return true;
        },
        getFolowngProduction() {
            let payload = {
                url: 'api/following-production/packing-list/' + this.itemId,
            }
            this.$store.dispatch(GET_ITEM_DETAIL_BY_ID, payload).then(result => {
                if (result.status) {
                    let results = [];
                    let item = result.data;
                    let color_and_size = result.data.data;
                    let imageIndex = 0;
                    color_and_size.forEach(subItem => {
                        let tmpItem = subItem
                        tmpItem.po_number = item.orderable ? item.orderable.po_number : "";
                        tmpItem.color_name = subItem.color_name;
                        tmpItem.color_number = subItem.color_number;
                        tmpItem.body_size = subItem.body_size;
                        tmpItem.amount = subItem.amount;
                        tmpItem.order_size_and_color_id = subItem.order_size_and_color_id;
                        tmpItem.order_number = item.orderable.order_number;
                        tmpItem.d_any = subItem.d_any;
                        tmpItem.upload_date = moment(subItem.upload_date).format(LARAVEL_DATE_FORMAT);
                        tmpItem.box_quantity = subItem.box_quantity;
                        tmpItem.broken_parcel = subItem.broken_parcel;
                        tmpItem.in_package = subItem.in_package;
                        tmpItem.box_weight = subItem.box_weight;
                        tmpItem.image = subItem.image;
                        //eğer kırık koli 1 den büyükse ekleme yapılıyor
                        tmpItem.broken_total_parcel = subItem.broken_parcel>0?Number(subItem.box_quantity)+1:subItem.broken_parcel;
                        tmpItem.image_link = item.image_link[imageIndex];
                        results.push(tmpItem);
                        imageIndex++;
                    })
                    this.status_id = item.status_id;
                    this.items = results;
                    this.orderInformations = results;
                    this.excelLink=item.excel_link;

                } else {
                    self.sweetAlertError(self.$t('general.internal_error'));
                }
            });
        },
    },
    data() {
        return {
            fieldsToViewEdit: [],
            editable: true,
            isDragging: false,
            delayedDragging: false,
            modelTableItem: [
                {
                    user_id: null,
                    model_name: null,
                    table_fields: {},
                }
            ],
            orderInformations: [],
            colorInformations: [],
            url: 'api/following-production/packing-list',
            orderUrl: 'api/following-production/packing-list/orders',
            isEditing: false,
            isCreating: false,
            search: null,
            status_id: null,
            page: 1,
            per_page: 10,
            total_quantity: 0,
            total_box_quantity: 0,
            form: {
                id: null,
                order_id: null,
                order_size_and_color_id: [],
            },
            items: [],
            orderOptions: [],
            orderOptionsRaw: [],
            itemId: this.$route.params.packing_id,
            activeItem: null,
            packingListStatuses: [],
            excelLink:null,
        };
    },
    methods: {
        ...mapMutations({
            setItems: SET_ITEMS,
            setError: SET_ERROR,
            setFilter: SET_FILTER,
        }),

        cancelClick() {
            this.$router.go(-1)
            this.clearFormItems();
        },
        onDocumentChange(payload) {
            let id = payload.id;
            let image = payload.file;
            let reader = new FileReader();
            reader.onload = function (e) {
                $('#' + id).attr('src', e.target.result);
            }
            reader.readAsDataURL(image); // convert to base64 string
        },
        openUploadImage(ref) {
            this.$refs[ref][0].openFileInput()
        },
        deleteClick() {
            let self = this;
            if (self.isUserGranted('Staff', ['followingExport', 'superAdmin'], false)) {
                self.sweetAlertConfirm(this.$t("general.are_you_sure"), function (self) {
                    let payload = {
                        url: 'api/following-production/packing-list/' + self.itemId,
                    }
                    self.$store.dispatch(DELETE_ITEM_BY_ID, payload).then((response) => {
                            self.clearFormItems();
                            self.getOrderOptions();

                            if (self.itemId) {
                                self.itemId = null;
                            }

                            self.sweetAlertSuccess(self.$t('general.successfully_deleted'));

                            self.$router.push({ name: 'following_production.packing_list.index' })
                    })
                }, () => {
                })
            }
        },
        storeData() {
            if (this.status_id == 27 || this.status_id >= 27) {
                if (!this.isUserGranted('Staff', ['followingExport', 'superAdmin'], false)) {
                    return false;
                }
            }

            this.orderInformations.forEach(item => {
                if (item.upload_date=='Invalid date') {
                    this.sweetAlertError(this.$t('packing_list.amount_error'));
                    return false;
                }
            })

            let self = this;

            if (self.isUserGranted('PackingList', ['create'])) {
                self.isEditing = true;
                let orderInformations = this.orderInformations;
                let form = new FormData();
                let transactions = [];

                orderInformations.forEach(item => {
                    let tmpItem = {};
                    tmpItem.order_size_and_color_id = item.order_size_and_color_id;
                    tmpItem.order_number = item.order_number;
                    tmpItem.po_number = item.po_number;
                    tmpItem.color_number = item.color_number;
                    tmpItem.color_name = item.color_name;
                    tmpItem.body_size = item.body_size;
                    tmpItem.amount = item.amount || 0;
                    tmpItem.d_any = item.d_any || 0;
                    tmpItem.upload_date = moment(item.upload_date).format(LARAVEL_DATE_FORMAT)
                    tmpItem.box_quantity = item.box_quantity || 0;
                    tmpItem.broken_parcel = item.broken_parcel || 0;
                    tmpItem.in_package = item.in_package || 0;
                    tmpItem.box_weight = item.box_weight || 0;
                    tmpItem.broken_total_parcel = item.broken_parcel>0?Number(item.box_quantity)+1:item.broken_parcel;
                    transactions.push(tmpItem)
                });

                this.createFormDataForList(form, 'data', transactions)

                orderInformations.forEach((item, index) => {
                    form.append('data[' + index + '][image]', item.image)
                });
                form.append('packing_list_id', this.itemId);
                form.append('is_editable', true);

                let payload = {
                    url: 'api/following-production/packing-list/update-item/' + this.itemId + '/update',
                    id: this.form.id,
                    contents: form,
                    returnType: 'stateless',
                    successMessage: this.$t('general.successfully_updated'),
                }

                this.$store.dispatch(CREATE_ITEM, payload)
                    .then((response) => {
                        if (response.status) {

                            self.clearFormItems();
                            self.getOrderOptions();

                            if (this.itemId) {
                                this.itemId = null;
                            }
                            self.sweetAlertSuccess(payload.successMessage);

                            this.$router.push({ name: 'following_production.packing_list.index' })

                        } else {
                            let result = response.data.response;
                            if (result.hasOwnProperty('data') && result.data.hasOwnProperty('message')) {
                                self.sweetAlertError(result.data.message);

                            } else {
                                self.sweetAlertError(result.data);
                            }
                        }
                        self.isEditing = false;
                    });
            }
        },
        clearFormItems() {
            this.form.id = null;
            this.form.order_id = null;
            this.form.order_size_and_color_id = [];
            this.orderInformations = [];
            this.isEditing = false;
            this.isCreating = false;
        },
        clickImage(index) {
            let ref = 'file' + index;
            this.$refs[ref][0].click()
        },
        imageChange(index) {
            let ref = 'file' + index;
            this.orderInformations[index].image = this.$refs[ref][0].files[0];
            let imageHolder = $('#image_holder-' + index);
            imageHolder.html(this.orderInformations[index].image.name);
        },
        onClickDelete(id) {
            let self = this;
            if (self.isUserGranted('PackingList', ['delete'])) {
                self.sweetAlertConfirm(this.$t("general.are_you_sure"), function (self) {
                    let payload = {
                        url: 'api/following-production/packing-list/' + id,
                        id: id,
                    }
                    self.$store.dispatch(DELETE_ITEM_BY_ID, payload)
                }, () => {
                })
            }
        },
        getOrderOptions() {
            let self = this;
            this.$store.dispatch(GET_ITEMS, {
                url: this.orderUrl,
                acceptPromise: true,
                returnType: 'stateless',
            }).then((result) => {
                if (result.status) {
                    self.orderOptions = self.convertArrayToObjectByKey(result.data, 'id', 'order_number');
                    self.orderOptionsRaw = result.data;
                }
            })
        },
    },
    created() {
        this.$store.commit(SET_LOADING, false);
        this.$store.commit(SET_ITEMS, {});
    },
    mounted() {
        this.$store.dispatch(SET_BREADCRUMB, [
            { title: this.$t("general.dashboard"), route: "dashboard" },
            { title: this.$t("menu.management.packing_list") }
        ]);
        this.getFolowngProduction


        setTimeout(() => {
            Object.keys(this.orderInformations).forEach(key => {
                $(`#preview-content-image-${key}`).attr('src', this.orderInformations[key]['image_link']);
            });
            //   this.orderInformations.forEach((item, index) => {
            //     $(`#preview-content-image-${index}`).attr('src', item.image_link);
            //   })
        }, 100);
    }
}
</script>

<style>

</style>